$(document).ready(() => {
  if ($(".hide_modal").length) {
    hide_modal();
  }
});

function hide_modal() {
  let button_yes = $('.yes-hide');
  $(button_yes).click(function () {
    $('.modal').modal('hide');
  });
};
